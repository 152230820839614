import { createStore, createEffect, createEvent, sample } from 'effector';
import * as R from 'ramda';
import { Mask, recognize, loadMask, loadSketch } from 'services/sketch';
import * as utils from './utils';
import { GroupData, ListData, SegmentData, SegmentDataFromSam, Manager } from './Manager';
import config from 'config';
import { demoSketch } from './constants';


export const $manager = createStore<Manager>(new Manager(demoSketch));
export const $managerTimout = createStore<NodeJS.Timeout | null>(null);
export const $queue = createStore(-1);

export const createManager = createEvent<{ id: string; file: File; list: Array<ListData>; segments: Array<SegmentData>; groups: Array<GroupData>; prototypeId: string; name: string }>();

export const recognizeFx = createEffect(recognize);
export const loadMaskFx = createEffect(loadMask);
export const loadSketchFx = createEffect(loadSketch);
export const loadFileFx = createEffect((id: string) => fetch(`${config.serverUrl}/drawing/${id}.png`).then(res => res.blob()))

loadSketchFx.doneData.watch(R.pipe(R.prop('config'), JSON.parse, createManager));

recognizeFx.doneData.watch(loadFileFx);

sample({
    clock: loadFileFx.done,
    source: $manager,
    fn: (manager, { params: id, result: blob }) => {
        manager.file = new File([blob], id);
        manager.id = id;
    },
});

sample({
    clock: recognizeFx.doneData,
    filter: Boolean,
    fn: id => setInterval(() => loadMaskFx(id), 1000),
    target: $managerTimout,
});

sample({
    source: $managerTimout,
    clock: loadMaskFx.doneData,
    filter: (_, value) => value instanceof Array,
    fn: value => {
        if (value) clearInterval(value);
        return null;
    },
    target: $managerTimout,
});

sample({
    clock: loadMaskFx.doneData,
    filter: value => !(value instanceof Array),
    fn: value => {
        if (value instanceof Array) throw new Error('Result is not array.');
        return value.queueSize;
    },
    target: $queue,
});

sample({
    clock: loadMaskFx.done,
    source: $manager,
    filter: (file, { result }) => Boolean(file) && result instanceof Array,
    fn: (manager, { result }) => {
        if (!(result instanceof Array)) throw new Error('Result is not array.');
        manager.init(result.map(maskToSegmentData), []);
    },
});

sample({
    clock: createManager,
    source: $manager,
    fn: (manager, payload) => {
        manager.init(payload.segments, payload.groups, payload.list, payload.id);
    },
});

function maskToSegmentData(mask: Mask): SegmentDataFromSam {
    return {
        name: mask.name,
        id: crypto.randomUUID(),
        attachments: [],
        color: utils.generateRandomColor(),
        description: '',
        groupId: null,
    };
}
