import { createEvent, createStore, sample } from 'effector';
import { closeModal } from 'entities/modal';
import { setTutorialDisabled, TutorialDisabledReasons } from 'entities/tutorial';
import { $userId, googleAuthFx, magicAuthFx } from 'entities/user';

export enum RoutePath {
    HOME = '/',
    SKETCH = '/sketch',
    RESULT = '/result',
    ENDING = '/ending',
}

export const $route = createStore<RoutePath>(RoutePath.HOME);

const popstate = createEvent<any>();
export const goTo = createEvent<RoutePath>();
export const closeSketch = createEvent();

$route
    .watch((state)=>{ if (state !== RoutePath.SKETCH) setTutorialDisabled(TutorialDisabledReasons.NONE)});

sample({
    clock: popstate,
    source: $userId,
    filter: () => Boolean(window.location.search),
    fn: () => {
        const code = new URLSearchParams(window.location.search).get('code');
        const jsonState = new URLSearchParams(window.location.search).get('state');
        window.history.replaceState(null, '', window.location.origin);
        if (!jsonState) throw new Error('State not exist.');
        const state = JSON.parse(jsonState);
        if (!code) throw new Error('Code not exist.');
        return { code, ...state };
    },
    target: googleAuthFx,
});

sample({
    clock: goTo,
    filter: value => value === RoutePath.HOME,
    fn: () => window.location.reload(),
});

sample({
    clock: goTo,
    filter: value => value !== RoutePath.HOME,
    target: $route,
});

closeSketch.watch(closeModal);

sample({
    clock: magicAuthFx.done,
    fn: () => RoutePath.HOME,
    target: goTo,
});

window.addEventListener('load', popstate);

window.addEventListener('popstate', popstate);
