import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import * as Listeners from '../Listeners';
import { Segment, useManager, Group as GroupClass } from 'entities/sketch/Manager';
import SegmentElement from './SegmentElement';
import { ReactComponent as CheckboxSmallCheckedSVG } from './icons/checkbox_small_checked.svg';
import { ReactComponent as CheckboxSmallSVG } from './icons/checkbox_small.svg';
import { ReactComponent as CreateEditGroupOfSimilarSVG } from './icons/create_edit_group_of_similar.svg';
import { ReactComponent as MatchDefinitionSVG } from './icons/match_definition.svg';
import { ReactComponent as UndefineSVG } from './icons/undefine.svg'
import { ReactComponent as DeleteSVG } from './icons/delete.svg';
import { ReactComponent as DefinitionSVG } from './icons/definition.svg';
import { Group, EditingGroup } from './Group';
import { setHint } from 'entities/hint';
import { useHotKey } from 'hooks';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { $elementForAttach } from 'entities/library';
import Attachments from '../Attachments';
import { $tutorial } from 'entities/tutorial';
import useScrollDetection from 'services/useScrollDetection';
import { recognizeFx } from 'entities/sketch';
import './List.scss';


type ListProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

export default function List({ listener, setListener }: ListProps) {
    const list = useManager('list');
    const selectedElements = useManager('selectedElements');
    const manager = useManager('self');
    const loaded = useManager('loaded');
    const editingGroup = useManager('editingGroup');
    const [matchDefinition, setMatchDefinition] = useState<((e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, id?: string) => void) | undefined>(undefined);
    const listRef = useRef<HTMLDivElement>(null);
    const isScrolling = useScrollDetection({ ref: listRef });
    const createGroupDisabled = useMemo(() => selectedElements.some(element => element instanceof GroupClass || element.groupId), [selectedElements]);
    const editDisabled = useMemo(() => selectedElements.some(element => element instanceof Segment) || selectedElements.length !== 1, [selectedElements]);
    const deleteDisabled = useMemo(() => selectedElements.some(element => element instanceof Segment && element.groupId) || selectedElements.length === 0, [selectedElements]);
    const undefineDisabled = useMemo(() => selectedElements.length === 0 || selectedElements.some(element => element instanceof Segment && element.groupId), [selectedElements]);
    const elementForAttach = useStore($elementForAttach);
    const tutorial = useStore($tutorial);

    const handleCreateGroup = useCallback(async () => {
        if (createGroupDisabled) return;
        if (selectedElements.some(element => element instanceof GroupClass)) throw new Error('Selected elements have Group.');
        const group = manager.createGroup(selectedElements as Array<Segment>);
        manager.editingGroup = group;
        manager.selectOneElement(group);
    }, [selectedElements, manager, createGroupDisabled]);

    useEffect(() => {
        if (listener instanceof Listeners.MatchDefinition) setMatchDefinition(() => (e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>, value: string) => listener.onClick(e, value));
        else setMatchDefinition(undefined);
    }, [listener]);

    const handleEdit = useCallback(() => {
        if (editDisabled) return;
        const group = selectedElements[0];
        if (!(group instanceof GroupClass)) throw new Error('Element is not group.');
        manager.editingGroup = group;
        manager.unselectAllElements();
    }, [selectedElements, editDisabled, manager]);

    const handleDelete = useCallback(async () => deleteDisabled || manager.removeElements(selectedElements), [selectedElements, manager, deleteDisabled]);

    const undefine = useCallback(() => {
        if (undefineDisabled) return;
        manager.undefine(selectedElements);
    }, [manager, selectedElements, undefineDisabled]);

    const matchDefinitionClick = useCallback(() => {
        if (listener instanceof Listeners.MatchDefinition) manager.matchDefinition = null;
        else setListener(new Listeners.MatchDefinition(manager))
    }, [manager, listener, setListener]);

    const setSelectListener = useCallback(() => setListener(new Listeners.Select(manager)), [manager, setListener]);
    const escapematchDefinitionOptions = useMemo(() => ({ condition: listener instanceof Listeners.MatchDefinition }), [listener]);

    useHotKey('Delete', handleDelete);
    useHotKey('Escape', setSelectListener, escapematchDefinitionOptions);

    const handleDragEnd = useCallback(async (event: DropResult) => {
        if (!event.destination) return;
        if (event.destination.index === event.source.index) return;

        if (!editingGroup) return manager.changeOrder(event.source.index, event.destination.index);

        const draggableElement = [...manager.groups, ...manager.segments].find(element => element.id === event.draggableId);
        if (!draggableElement) throw new Error('Drag error.');

        const groupIndex = list.findIndex(element => element === editingGroup);
        const term = event.source.index < groupIndex ? 1 : 0;
        const toGroup = event.destination.index + term > groupIndex && event.destination.index <= (groupIndex + editingGroup.segments.length);
        if (toGroup) {
            const term = event.source.index > groupIndex ? -1 : 0;
            const fromGroup = event.source.index > groupIndex && event.source.index <= (groupIndex + editingGroup.segments.length);
            if (fromGroup) return editingGroup.changeSegmentPosition(event.source.index - groupIndex - 1, event.destination.index - groupIndex - 1);
            else {
                if (draggableElement instanceof GroupClass) return;
                return manager.addSegmentToGroup(draggableElement, editingGroup, event.destination.index - groupIndex + term);
            }
        } else {
            const term = event.destination.index <= groupIndex ? -1 : 0;
            const sourceIndex = event.source.index < groupIndex ? event.source.index : (event.source.index - editingGroup.segments.length);
            const destinationIndex = event.destination.index <= groupIndex ? event.destination.index : (event.destination.index - editingGroup.segments.length);
            const fromGroup = event.source.index > groupIndex && event.source.index <= (groupIndex + editingGroup.segments.length);
            if (fromGroup) {
                if (draggableElement instanceof GroupClass) return;
                manager.removeSegmentFromGroup(draggableElement, editingGroup, destinationIndex + 1 + term);
            }
            else return manager.changeOrder(sourceIndex, destinationIndex);
        }
    }, [manager, editingGroup, list]);

    const createList = useMemo(() => {
        let counter = 0;
        let editGroupCounter = 0;
        return list.map((element, index) => {
            if (element instanceof Segment) return (<SegmentElement matchDefinition={matchDefinition} segment={element} realIndex={editGroupCounter === 0 ? index : index - 1} index={index + counter} key={element.id} />);

            if (element instanceof GroupClass) {
                if (element === editingGroup) {
                    counter += element.segments.length;
                    editGroupCounter += 1;
                    if (tutorial.step === 0) return (<EditingGroup matchDefinition={matchDefinition} group={element} index={index + counter - element.segments.length} key={element.id} />);
                    else return null
                }
                return (<Group matchDefinition={matchDefinition} group={element} index={index + counter} key={element.id} realIndex={editGroupCounter === 0 ? index : index - 1} />);
            }
            return null;
        })
    }, [list, editingGroup, matchDefinition, tutorial.step]);

    useEffect(() => {
        if (tutorial.step === 1) {
            setTimeout(() => {
                if (listRef.current) listRef.current.scrollTo({ top: 0, behavior: "smooth" });
            }, 100);
        }
    }, [tutorial.step]);

    useEffect(() => {
        if (isScrolling) setHint(null);
    }, [isScrolling]);

    return (
        <div className="list_wrapper">
            {!loaded && <Recognizing />}
            <Attachments element={elementForAttach} />
            <div className="list_tools">
                <div className="list__toolbar" id="list__toolbar">
                    <div
                        id="edit_group"
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': editDisabled && createGroupDisabled })}
                        onClick={() => {
                            if (editDisabled) return handleCreateGroup();
                            if (createGroupDisabled) return handleEdit();
                        }}
                        onMouseEnter={() => setHint({ id: 'edit_group' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <CreateEditGroupOfSimilarSVG />
                        <div>CREATE/EDIT<br />GROUP OF SIMILAR</div>
                    </div>
                    <div className="list__toolbar-gap" />
                    <div
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_selected': listener instanceof Listeners.MatchDefinition })}
                        onClick={matchDefinitionClick}
                    >
                        <MatchDefinitionSVG />
                        <div>MATCH<div />DEFINITION</div>
                    </div>
                    <div className="list__toolbar-gap" />
                    <div className="list__toolbar-br" />
                    <div className="list__toolbar-gap" />
                    <div
                        id="undefine"
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': undefineDisabled })}
                        onClick={undefine}
                        onMouseEnter={() => setHint({ id: 'undefine' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <UndefineSVG />
                        <div>UNDEFINE</div>
                    </div>
                    <div className="list__toolbar-gap" />
                    <div
                        id="delete"
                        className={classNames({ 'list__toolbar-button': true, 'list__toolbar-button_disabled': deleteDisabled || editingGroup })}
                        onClick={handleDelete}
                        onMouseEnter={() => setHint({ id: 'delete' })}
                        onMouseLeave={() => setHint(null)}
                    >
                        <DeleteSVG />
                        <div>DELETE</div>
                    </div>
                    <div className="list__toolbar-gap" />
                    <div className="list__toolbar-sizer" />
                    <button className='render__button' onClick={() => setLeftSidePanel(LeftSidePanel.SUBMIT_FOR_RENDERING)}>
                        <div className='render__button_icon' />
                        <span>RENDER</span>
                    </button>
                </div>
                <div id="list__head" className="list__head">
                    <Checkbox />
                    <div className="list__head-hash-cell" id="grid">#</div>
                    <div className="list__head-definition-cell">
                        <DefinitionSVG />
                        <span>DEFINITION</span>
                    </div>
                </div>
            </div>
            <div className={classNames({ 'list': true, 'list_scrolling': isScrolling })} ref={listRef}>
                <div className="list__table" >
                    <div className='list__table-elements-wrapper' id='list__table-elements'>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="single">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {createList}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Checkbox() {
    const list = useManager('list');
    const selectedElements = useManager('selectedElements');
    const manager = useManager('self');
    const loaded = useManager('loaded');

    const partSelected = useMemo(() => selectedElements.length > 0 && selectedElements.length < list.length, [selectedElements, list]);
    const allSelected = useMemo(() => selectedElements.length === list.length, [selectedElements, list]);

    const handleClick = useCallback(() => {
        if (allSelected) return manager.unselectAllElements();
        if (partSelected) return manager.unselectAllElements();
        manager.selectElements(list);
    }, [partSelected, allSelected, manager, list]);

    return (
        <div
            className="list__head-checkbox-cell"
            id="checkbox"
            onMouseEnter={() => setHint({ id: partSelected || allSelected ? 'checkbox_selected' : 'checkbox' })}
            onMouseLeave={() => setHint(null)}>
            {
                !loaded || partSelected || allSelected
                    ?
                    <CheckboxSmallCheckedSVG className="pointer" onClick={handleClick} />
                    :
                    <CheckboxSmallSVG className="pointer" onClick={handleClick} />
            }
        </div>
    );
}

function Recognizing() {
    const file = useManager('file');
    const uploaded = useManager('uploaded');
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(0);

    const start = useCallback(() => {
        if (!file) return;
        recognizeFx(file);
        setLoading(true);
    }, [file]);

    useEffect(() => {
        const interval = setInterval(() => setTime(prev => ++prev), 930);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="recognizing__background">
            <div className="recognizing">
                {loading || uploaded
                    ?
                    <div className="recognizing__loader" />
                    :
                    <div className={classNames({ recognizing__number: true, recognizing__number_disabled: !file })}>2</div>
                }
                {
                    uploaded
                        ?
                        <>
                            <div className="recognizing__title">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                            <div className={classNames({ recognizing__button: true, recognizing__button_disabled: !file })} onClick={start}>LOADING{'.'.repeat(time % 4)}</div>
                        </>
                        :
                        loading
                            ?
                            <>
                                <div className="recognizing__title">AI is recognizing the objects in your image.<br />This process may take a few minutes.</div>
                                <div className={classNames({ recognizing__button: true, recognizing__button_loading: true })}>Stay tuned{'.'.repeat(time % 4)}</div>
                            </>
                            :
                            <>
                                <div className="recognizing__title">Ask AI to identify design image elements</div>
                                <div className={classNames({ recognizing__button: true, recognizing__button_disabled: !file })} onClick={start}>START</div>
                            </>
                }
            </div>
        </div >
    );
}
