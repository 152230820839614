import classNames from 'classnames';
import './Switcher.scss';
import { MouseEventHandler } from 'react';

type SwitcherProps = {
    value: boolean;
    onClick: MouseEventHandler<HTMLDivElement>;
    disabled?: boolean;
};

export default function Switcher({ value, onClick, disabled = false }: SwitcherProps) {
    return (
        <div className={classNames({ switcher: true, switcher_active: value, switcher_disabled: disabled })} onClick={e => disabled || onClick(e)}>
            <div className={classNames({ switcher__stick: true, switcher__stick_active: value })} />
        </div>
    );
}