import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import * as Listeners from '../Listeners';
import { ReactComponent as PlusSVG } from './icons/plus.svg';
import { ReactComponent as MinusSVG } from './icons/minus.svg';
import { ReactComponent as SyncSVG } from './icons/sync.svg';
import { ReactComponent as SpinSVG } from './icons/spin.svg';
import { Group, Segment, useEditor, useManager } from 'entities/sketch/Manager';
import useStatusBar from './hooks/useStatusBar';
import { setHint } from 'entities/hint';
import useCursor from './hooks/useCursos';
import { $modal, ModalType } from 'entities/modal';
import { $elementForAttach } from 'entities/library';
import Switcher from 'Components/Switcher';
import { $tutorial } from 'entities/tutorial';
import { SpriteState } from 'entities/sketch/Engine/Engine';
import { Notifications, setFileName, setNotification } from 'entities/notification';
import { recognizeFx } from 'entities/sketch';
import './Drawing.scss';


type DrawingProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

export default function Drawing({ listener, setListener }: DrawingProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [masksHidden, setMasksHidden] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [size, setSize] = useState<{ width: 'auto' | number, height: number }>({ width: 'auto', height: 300 });
    const [undefinedMode, setUndefinedMode] = useState<boolean>(false);
    const [grabbing, setGrabbing] = useState(false);
    const [ctrl, setCtrl] = useState(false);
    const [shift, setShift] = useState(false);
    const editing = useEditor('editing');
    const modal = useStore($modal);
    const elementForAttach = useStore($elementForAttach);
    const drawingFooterRef = useRef<HTMLDivElement | null>(null);
    const tutorial = useStore($tutorial);
    const manager = useManager('self');
    const uploaded = useManager('uploaded');
    const loaded = useManager('loaded');
    const file = useManager('file');
    const deltaFrame = useManager('deltaFrame');

    useCursor(listener);

    useEffect(() => {
        const canvas = document.getElementById('drawing__container');
        if (!canvas) return;
        let prev: Listeners.Listener | undefined;

        function onMouseDown(e: MouseEvent) {
            if (e.button !== 1) return;
            setListener(value => {
                prev = value;
                return new Listeners.Zoom(manager);
            });
            listener.onMouseDown(e);
        }

        function onMouseUp() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
            listener.onMouseLeave();
        }

        canvas.addEventListener('mousedown', onMouseDown);
        canvas.addEventListener('mouseup', onMouseUp);
        canvas.addEventListener('mouseleave', onMouseUp);
        return () => {
            canvas.removeEventListener('mousedown', onMouseDown);
            canvas.removeEventListener('mouseup', onMouseUp);
            canvas.removeEventListener('mouseleave', onMouseUp);
        };
    }, [manager, listener, setListener]);

    const { startDrag, endDrag } = useMemo(() => {
        let prev: Listeners.Listener | undefined;

        function startDrag(e?: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) {
            setListener(value => {
                if (value instanceof Listeners.Zoom) return value;
                prev = value;
                const next = new Listeners.Zoom(manager);
                if (e) next.onMouseDown(e);
                return next;
            });
        }

        function endDrag() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
        }

        return { startDrag, endDrag };
    }, [manager, setListener]);

    useEffect(() => {
        function start(e: KeyboardEvent) {
            if (e.code === 'Space' && document.activeElement instanceof HTMLBodyElement) startDrag();
        }

        function end(e: KeyboardEvent) {
            if (e.code === 'Space') endDrag();
        }

        document.addEventListener('keydown', start);
        document.addEventListener('keyup', end);

        return () => {
            document.removeEventListener('keydown', start);
            document.removeEventListener('keyup', end);
        };
    }, [startDrag, endDrag]);

    useEffect(() => {
        if (modal.type === ModalType.NONE && !elementForAttach) {
            function fn(e: KeyboardEvent) {
                if (e.code !== 'Escape') return;

                manager.unselectAllElements();
            }

            document.addEventListener('keydown', fn);
            return () => document.removeEventListener('keydown', fn);
        }
    }, [modal, manager, elementForAttach]);

    const treeWheel = useCallback((event: React.WheelEvent<HTMLCanvasElement>) => {
        if (tutorial.step !== 0) return
        manager.scale(event);
    }, [manager, tutorial.step]);

    useEffect(() => {
        const handleKeydown = (e: KeyboardEvent) => {
            if (listener instanceof Listeners.Select && e.key === 'Tab') {
                e.preventDefault();
                listener.handleTab();
            }
        }

        document.addEventListener('keydown', handleKeydown);

        return () => document.removeEventListener('keydown', handleKeydown);
    }, [listener]);

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        if (!(canvas instanceof HTMLCanvasElement)) throw new Error('canvas not canvas =)');
        if (!manager) throw new Error('Segments not exist');
        manager.connectCanvas(canvas);
    }, [manager]);

    //CHECK
    useEffect(() => {
        if (tutorial.step === 0) return;

        manager.fit();
        manager.updateTutorialDraw(tutorial.step);
    }, [manager, tutorial.step])

    useEffect(() => {
        if (editing) return;

        if (masksHidden) return manager.segments.forEach(segment => segment.state = SpriteState.HIDE);

        if (undefinedMode) return manager.segments.forEach(segment => {
            if ((segment.description === '') && segment.attachments.length === 0) segment.state = SpriteState.REGULAR;
            else segment.state = SpriteState.HIDE;
        });

        return manager.segments.forEach(segment => segment.state = SpriteState.REGULAR);
    }, [undefinedMode, masksHidden, editing, manager]);

    useEffect(() => {
        const container = ref.current;
        if (!container) throw new Error('Container not exist.');

        const resizeObserver = new ResizeObserver(e => setSize({ width: e[0].contentRect.width, height: e[0].contentRect.height }));
        resizeObserver.observe(container);

        return () => resizeObserver.unobserve(container);
    }, []);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) return;

        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "[") manager.changeCursorRadius(-1);

            if (event.key === "]") manager.changeCursorRadius(1);
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.BrushToolListener)) manager.setCursor(null);
    }, [listener, manager]);

    useEffect(() => {
        if (!(listener instanceof Listeners.Zoom)) setGrabbing(false);
        else listener.setGrabbing = setGrabbing;
    }, [listener]);

    useEffect(() => {
        function down(event: KeyboardEvent) {
            if (event.ctrlKey || event.metaKey) setCtrl(true);
            if (event.shiftKey) setShift(true);
        }
        function up(event: KeyboardEvent) {
            if (!event.ctrlKey && !event.metaKey) setCtrl(false);
            if (!event.shiftKey) setShift(false);
        }

        document.addEventListener('keydown', down);
        document.addEventListener('keyup', up);

        return () => {
            document.removeEventListener('keydown', down);
            document.removeEventListener('keyup', up);
        }
    }, []);

    const onMouseDown = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent> | MouseEvent) => {
        if (e.button !== 1) return listener.onMouseDown(e);

        startDrag(e);
    }, [listener, startDrag]);

    const onMouseUp = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onMouseUp(e);

        endDrag();
    }, [listener, endDrag]);

    const onMouseClick = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (e.button !== 1) return listener.onClick(e);
    }, [listener]);

    const checkEndSetFile = useCallback((file: File | undefined) => {
        if (!file) throw new Error('File not exist.');

        const maxSize = 36 * 1024 * 1024;
        if (!file) throw new Error('File not exist.');
        setFileName(file.name);
        if (file.size > maxSize) return setNotification(Notifications.UPLOAD_SIZE_ERROR);
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) return setNotification(Notifications.UPLOAD_EXT_ERROR);
        setNotification(null);

        manager.file = file;
    }, [manager]);

    const handleDrop = useCallback((e: React.DragEvent<HTMLCanvasElement>) => {
        setIsDragging(false);
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleDrag = useCallback((e: React.DragEvent<HTMLCanvasElement>) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const dragLeave = useCallback(() => setIsDragging(false), []);

    return (
        <div className={classNames({ drawing: true, drawing_dragging: isDragging })} ref={ref} >
            {!uploaded && <Upload checkEndSetFile={checkEndSetFile} />}
            <canvas
                id="canvas"
                className={classNames({
                    drawing__canvas: true,
                    'cursor-frame-to-add': listener instanceof Listeners.BoxListener,
                    'cursor-selection': listener instanceof Listeners.Select || (deltaFrame !== null && deltaFrame === 0),
                    'cursor-selection-add': listener instanceof Listeners.Select && ctrl && !deltaFrame,
                    'cursor-selection-remove': listener instanceof Listeners.Select && shift && !deltaFrame,
                    'cursor-cross-selection': deltaFrame !== null && deltaFrame < 0,
                    'cursor-cross-selection-add': deltaFrame !== null && deltaFrame < 0 && ctrl,
                    'cursor-cross-selection-remove': deltaFrame !== null && deltaFrame < 0 && shift,
                    'cursor-window-selection': deltaFrame !== null && deltaFrame > 0,
                    'cursor-window-selection-add': deltaFrame !== null && deltaFrame > 0 && ctrl,
                    'cursor-window-selection-remove': deltaFrame !== null && deltaFrame > 0 && shift,
                    'cursor-add': listener instanceof Listeners.PositivePoint,
                    'cursor-remove': listener instanceof Listeners.NegativePoint,
                    'cursor-brush-tool': listener instanceof Listeners.BrushToolListener,
                    'cursor-grab': listener instanceof Listeners.Zoom,
                    'cursor-grabbing': grabbing,
                })}
                onWheel={treeWheel}
                onMouseDown={e => onMouseDown(e)}
                onMouseMove={e => listener.onMouseMove(e)}
                onMouseUp={e => onMouseUp(e)}
                onClick={e => onMouseClick(e)}
                onDoubleClick={e => listener.onDoubleClick(e)}
                onMouseLeave={() => listener.onMouseLeave()}
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragLeave={dragLeave}
                {...size}
            />
            {/* {tutorial && tutorial.step !== 0 && <div className='drawing__footer-bar_cover' style={{ height: drawingFooterHeight }} />} */}
            <div className="drawing__footer">
                <div className="drawing__footer-bar" ref={drawingFooterRef}>
                    <div className="drawing__footer-bar-left">
                        <div
                            id="undefined-switcher"
                            className="drawing__switcher"
                            onMouseEnter={() => setHint({ id: 'undefined-switcher' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <Switcher value={undefinedMode} onClick={() => setUndefinedMode(prev => !prev)} disabled={!loaded} />
                            <div className={classNames({ disabled: !loaded })}>UNDEFINED</div>
                        </div >
                        <div
                            id="original_switcher"
                            className="drawing__switcher"
                            onMouseEnter={() => setHint({ id: 'original_switcher' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <Switcher value={masksHidden} onClick={() => setMasksHidden(prev => !prev)} disabled={!loaded} />
                            <div className={classNames({ disabled: !loaded })}>MASKS</div>
                        </div>
                        <div
                            id="original_switcher"
                            className="drawing__switcher"
                        >
                            <Switcher value={false} onClick={() => undefined} disabled />
                            <div className={classNames({ disabled: true })}>CUT-OUTS</div>
                        </div>
                    </div >
                    <div className={classNames({ 'drawing__zoom-buttons': true, 'drawing__zoom-buttons_disabled': !file })} id="drawing__zoom-buttons">
                        <div
                            className="drawing__zoom-minus"
                            onClick={() => file && manager.scaleFromButton(1)}
                            id="zoom_in"
                            onMouseEnter={() => setHint({ id: 'zoom_in' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <MinusSVG />
                        </div>
                        <div
                            id="zoom_out"
                            className="drawing__zoom-plus"
                            onClick={() => file && manager.scaleFromButton(-1)}
                            onMouseEnter={() => setHint({ id: 'zoom_out' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <PlusSVG />
                        </div>
                        <div
                            id="zoom_fit"
                            className="drawing__zoom-fit" onClick={() => file && manager.fit()}
                            onMouseEnter={() => setHint({ id: 'zoom_fit' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            FIT
                        </div>
                    </div>
                </div >
                <FooterState listener={listener} />
            </div>
        </div >
    );
}

type UploadProps = {
    checkEndSetFile: (file: File | undefined) => Notifications | null | undefined;
};

function Upload({ checkEndSetFile }: UploadProps) {
    const file = useManager('file');
    const [loading, setLoading] = useState<false | File>(false);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (!files) return console.error('Files not exist.');
        const file = files[0];
        checkEndSetFile(file);
    }, [checkEndSetFile]);

    const handleUpload = useCallback(() => {
        const input = document.getElementById('input');
        if (!input) throw new Error('Input not found.');
        input.click();
    }, []);

    useEffect(() => recognizeFx.watch(setLoading).unsubscribe, []);

    return (
        <>
            <input id="input" onChange={handleChange} accept='image/jpeg, image/jpg, image/png' type='file' hidden />
            {!loading &&
                (file
                    ?
                    <UploadReplaceButton handleUpload={handleUpload} />
                    :
                    <UploadFrame handleUpload={handleUpload} />)
            }
        </>
    );
}

type UploadFrameProps = {
    handleUpload: () => void;
};

function UploadFrame({ handleUpload }: UploadFrameProps) {
    return (
        <div className="upload__frame">
            <div className="upload__number">1</div>
            <div className="upload__title">Drag & drop or Browse designe image to render</div>
            <div className="upload__button" onClick={handleUpload}>BROWSE</div>
            <div className="upload__note">Only files with the following extensions are allowed: png, jpg, jpeg. <br /> 36Mb limit, 1 file only</div>
        </div>
    );
}

type UploadReplaceButtonProps = {
    handleUpload: () => void;
};

function UploadReplaceButton({ handleUpload }: UploadReplaceButtonProps) {
    return (
        <div className="upload__replace-button" onClick={handleUpload}>
            <SyncSVG />
            <span>replace image</span>
        </div>
    );
}

type FooterStateProps = {
    listener: Listeners.Listener;
};

function FooterState({ listener }: FooterStateProps) {
    const manager = useManager('self');
    const list = useManager('list');
    const status = useStatusBar(listener);
    const onnxState = useEditor('onnxState');
    const editing = useEditor('editing');
    const selectedElements = useManager('selectedElements');
    const [prevSelectedElements, setPrevSelectedElements] = useState<Array<Array<Segment | Group>>>([[], []]);

    useEffect(() => setPrevSelectedElements(prev => [prev[1], selectedElements]), [selectedElements]);
    useEffect(() => setPrevSelectedElements([[], []]), [list]);

    const reselect = useCallback(() => manager.selectElements(prevSelectedElements[0]), [manager, prevSelectedElements]);

    return (
        <div className="drawing__footer-state">
            <span>{status}</span>
            {onnxState.busy
                ?
                <div className="drawing__footer-ai-state">
                    <span>AI is drawing masks</span>
                    <SpinSVG className="drawing__footer-ai-state-spin" />
                </div>
                :
                <>{
                    Boolean(!editing)
                    &&
                    <div className="drawing__footer-reselect-container">
                        {
                            Boolean(prevSelectedElements[0].length)
                            &&
                            <div
                                id="reselect"
                                onClick={reselect}
                                className="drawing__footer-reselect"
                                onMouseEnter={() => setHint({ id: 'reselect' })}
                                onMouseLeave={() => setHint(null)}
                            >Reselect</div>
                        }
                        {
                            <div className="drawing__footer-items">Selected item(s): {selectedElements.length}</div>
                        }
                    </div>
                }</>
            }
        </div >
    );
}