import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import * as Listeners from './Listeners';
import Drawing from './Drawing';
import LeftBar from './LeftBar';
import List from './List';
import Tutorial from './Tutorial/Tutorial';
import useStyles from './useStyles';
import { useManager } from 'entities/sketch/Manager';
import Header from 'Components/Header';
import { $tutorial, $tutorialDisabled, /* setPrevSketchState,  */setTutorialDisabled, skip, TutorialDisabledReasons } from 'entities/tutorial';
import { useHotKey } from 'hooks';
import classNames from 'classnames';
import MatchDefinitionHint from './MatchDefinitionHint';
import './Sketch.scss';


export default function Sketch() {
    const list = useManager('list');
    const segmentEditor = useManager('segmentEditor');
    const matchDefinition = useManager('matchDefinition');
    const tutorial = useStore($tutorial);
    const [showTutorial, setShowTutorial] = useState<boolean>(false);
    const tutorialDisabled = useStore($tutorialDisabled);
    const manager = useManager('self');
    const [listener, setListener] = useState<Listeners.Listener>(() => new Listeners.Zoom(manager));

    useStyles();

    useEffect(() => {
        if (list.length < 3 || segmentEditor.editing) {
            if (list.length < 3) setTutorialDisabled(TutorialDisabledReasons.NO_ELEMENTS);
            else setTutorialDisabled(segmentEditor.editing ? TutorialDisabledReasons.IS_EDIT_MODE : TutorialDisabledReasons.NONE);
            skip();
            setShowTutorial(false);

        } else {
            setTutorialDisabled(TutorialDisabledReasons.NONE);
            setShowTutorial(true);
        }

        /*         if(tutorial.step!==0 && tutorialDisabled===TutorialDisabledReasons.NONE){
                    if(manager.hideSegments || manager.undefinedMode) setPrevSketchState({showUndefined: manager.undefinedMode, hideMasks: manager.hideSegments})
                    if (manager.hideSegments) manager.hideSegments = false
                    if (manager.undefinedMode) manager.undefinedMode = false;
                }  */
        if (tutorial.step === 0) setShowTutorial(false)


    }, [list, manager, tutorial.step, segmentEditor?.editing, list.length, tutorialDisabled]);

    const setMatchDefinition = useCallback(() => {
        if (listener instanceof Listeners.MatchDefinition) manager.matchDefinition = null;
        else setListener(new Listeners.MatchDefinition(manager))
    }, [manager, listener]);

    useHotKey('i', setMatchDefinition);

    return (
        <div
            className={classNames({
                sketch: true,
                'cursor-match-definition': listener instanceof Listeners.MatchDefinition,
                'cursor-match-definition-full': listener instanceof Listeners.MatchDefinition && matchDefinition && matchDefinition.full,
            })}
        >
            <MatchDefinitionHint />
            <Header type="sketch" />
            <div className="sketch__body">
                <LeftBar listener={listener} setListener={setListener} />
                <Drawing listener={listener} setListener={setListener} />
                <List listener={listener} setListener={setListener} />
            </div>
            {showTutorial && <Tutorial />}
        </div >
    );
}
