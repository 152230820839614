import { useCallback, useEffect, useState } from 'react';

type Options = {
    modifier?: 'ctrlKey',
    condition?: boolean,
};

export function useHotKey(key: string, fn: () => any, options: Options = {}) {
    useEffect(() => {
        function checkKey(event: KeyboardEvent) {
            if (options.modifier && !event[options.modifier]) return;
            if (options.condition !== undefined && !options.condition) return;
            if (event.key !== key) return;
            fn();
        }
        document.addEventListener('keydown', checkKey);

        return () => document.removeEventListener('keydown', checkKey);
    }, [key, fn, options]);
}

export function useElementHeight(ref: React.MutableRefObject<HTMLDivElement | null>, defaultHeight = '0px'): [string, () => void, () => void] {
    const [height, setHeight] = useState(defaultHeight);

    const updateHeight = useCallback(() => {
        const element = ref.current
        if (!element) throw new Error('Element is null.');
        setHeight(element.scrollHeight + 1 + 'px');
    }, [ref]);

    useEffect(() => {
        const element = ref.current
        if (!element) throw new Error('Element is null.');

        function transitionend(this: HTMLDivElement) {
            const height = this.style.height;
            if (height !== '0px') {
                setHeight('auto');
                this.style.overflow = 'visible';
            }
        }
        element.addEventListener('transitionend', transitionend);

        const resizeObserver = new ResizeObserver(updateHeight);

        return () => {
            resizeObserver.disconnect();
            element.removeEventListener('transitionend', transitionend);
        }
    }, [ref, updateHeight]);

    const open = useCallback(updateHeight, [updateHeight]);
    const close = useCallback(() => {
        const element = ref.current
        if (!element) throw new Error('Element is null.');

        element.style.overflow = 'hidden';
        element.style.height = element.scrollHeight + 1 + 'px';
        setTimeout(() => setHeight('0px'), 0);
    }, [ref]);

    return [height, open, close];
}