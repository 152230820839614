export enum ModalType {
    NONE = 'NONE',
    JOIN = 'JOIN',
    SKETCH_LEAVING = 'SKETCH_LEAVING',
    ERROR = 'ERROR',
    ATTACH_ERROR = 'ATTACH_ERROR',
    SHARE = 'SHARE',
}

export type JoinModal = {
    type: ModalType.JOIN;
    props: {
        res: (value: boolean) => void;
        rej: () => void;
    };
};

export type ShareRenderModal = {
    type: ModalType.SHARE;
    props: {
        id: string;
    };
};

export type Modal = {
    type: ModalType.NONE;
    props?: undefined;
} | {
    type: ModalType.ERROR;
    props?: undefined;
} | {
    type: ModalType.SKETCH_LEAVING;
    props?: undefined;
} | {
    type: ModalType.ATTACH_ERROR;
    props?: undefined;
} | ShareRenderModal | JoinModal;
