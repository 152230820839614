import { useCallback, useMemo, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as RemoveSVG } from './icons/remove.svg';
import { ReactComponent as ExitSVG } from './icons/exit.svg';
import { Attachment, Group, Segment, useAttachment, useManager } from 'entities/sketch/Manager';
import { useStore } from 'effector-react';
import { $userId } from 'entities/user';
import './Attachment.scss';


type AttachmentProps = {
    attachment: Attachment;
    element: Segment | Group;
};

export function AttachmentComponent({ element, attachment }: AttachmentProps) {
    const manager = useManager('self');
    const userId = useStore($userId);
    const [preview, setPreview] = useState<string | null>(null);
    const { originalname } = attachment;
    const note = useAttachment(attachment, 'note');
    const [prevNote, setPrevNote] = useState(note);
    const ext = useMemo(() => originalname.split('.').splice(-1)[0], [originalname]);

    const fileIsImage = useMemo(() => ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase()), [ext]);

    const onBlur = useCallback(() => {
        if (prevNote === attachment.note) return;
        else manager.saveSketch();
    }, [manager, attachment, prevNote]);

    return (
        <div className="attachment">
            {preview && <Preview src={preview} exit={() => setPreview(null)} />}
            <div />
            {
                fileIsImage
                    ?
                    <img className="attachment__image" alt="" src={attachment.getImageSrc(userId)} onClick={() => setPreview(attachment.getImageSrc(userId))} />
                    :
                    <div className={`attachment__ext ext-${ext.toLowerCase()}`} />
            }
            <div className="attachment__cell3">
                <div
                    className="attachment__filename"
                    onClick={() => setPreview(attachment.getImageSrc(userId))}
                >
                    {originalname}
                </div>
                <TextareaAutosize
                    maxRows={4}
                    value={note}
                    onChange={e => attachment.note = e.currentTarget.value}
                    className="attachment__note"
                    placeholder="Explain or clarify the attachment"
                    onFocus={() => setPrevNote(note)}
                    onBlur={onBlur}
                    data-type="input"
                />
            </div>
            <div className="attachment__remove" onClick={() => element.unattach(attachment)}>
                <RemoveSVG />
            </div>
        </div >
    );
}

export function GroupSegmentAttachmentComponent({ attachment }: AttachmentProps) {
    const manager = useManager('self');
    const userId = useStore($userId);
    const [preview, setPreview] = useState<string | null>(null);
    const { originalname } = attachment;
    const note = useAttachment(attachment, 'note');
    const [prevNote, setPrevNote] = useState(note);
    const ext = useMemo(() => originalname.split('.').splice(-1)[0], [originalname]);

    const fileIsImage = useMemo(() => ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase()), [ext]);

    const onBlur = useCallback(() => {
        if (prevNote === attachment.note) return;
        else manager.saveSketch();
    }, [manager, attachment, prevNote]);

    return (
        <div className="group-segment-attachment">
            {preview && <Preview src={preview} exit={() => setPreview(null)} />}
            <div />
            {
                fileIsImage
                    ?
                    <img className="group-segment-attachment__image" alt="" src={attachment.getImageSrc(userId)} onClick={() => setPreview(attachment.getImageSrc(userId))} />
                    :
                    <div className={`group-segment-attachment__ext ext-${ext.toLowerCase()}`} />
            }
            <div className="group-segment-attachment__cell3">
                <div
                    className="group-segment-attachment__filename"
                    onClick={() => setPreview(attachment.getImageSrc(userId))}
                >
                    {originalname}
                </div>
                <TextareaAutosize
                    maxRows={4}
                    value={note}
                    disabled
                    onChange={e => attachment.note = e.currentTarget.value}
                    className="group-segment-attachment__note"
                    placeholder="Explain or clarify the attachment"
                    onFocus={() => setPrevNote(note)}
                    onBlur={onBlur}
                    data-type="input"
                />
            </div>
        </div >
    );
}

type PreviewProps = {
    src: string;
    exit: () => void;
};

function Preview({ src, exit }: PreviewProps) {
    return (
        <div className="attachment-preview">
            <div className="attachment-preview__container">
                <ExitSVG className="attachment-preview__exit-button" onClick={exit} />
                <img className="attachment-preview__image" src={src} alt="preview" />
            </div>
        </div>
    );
}
